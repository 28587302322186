import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';

import { Companie } from './companie.model';
import { Observable, pipe, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseService } from '../../shared/services/base.service';
import { CompanieForUpdate } from './companie-for-update.model';
import { CompanieForCreation } from './companie-for-creation.model';
import { Leads } from 'app/leads/shared/leads.model';

@Injectable({
  providedIn: 'root'
})
export class CompaniesService extends BaseService {

  // apiUrl = 'http://localhost:50410/api';
  lastUrl: string;
  searchValue: string;
  goBack: boolean;

  constructor(public http: HttpClient) {
    super();
   }
   getLastUrl(): Observable<HttpResponse<Array<Companie>>> {
    return this.http.get<Array<Companie>>(this.lastUrl, { observe: 'response'});
  }
  getCompanies(): Observable<HttpResponse<Array<Companie>>> {
    return this.http.get<Array<Companie>>(this.apiUrl + '/companies/', { observe: 'response'});
  }
  getExportCompanies(value: string): Observable<HttpResponse<Array<Companie>>> {
    return this.http.get<Array<Companie>>(this.apiUrl + '/companies/export?searchquery=' + value, { observe: 'response' });
  }
  getAllCompanies(): Observable<HttpResponse<Array<Companie>>> {
    return this.http.get<Array<Companie>>(this.apiUrl + '/companies/all', { observe: 'response'});
  }
  getCompaniesById(id: string): Observable<CompanieForUpdate> {
    let companieForController: CompanieForUpdate = new CompanieForUpdate;
    return this.http.get<CompanieForUpdate>(this.apiUrl + '/companies/' + id).pipe(
      map(dbCompanie => {
        companieForController = automapper.map('CompanieModel', 'CompanieFromDB', dbCompanie);
        return companieForController;
      })
    )
  }
  getCompaniesByPage(maxItems: number, pageNumber: number): Observable<HttpResponse<Array<Companie>>> {
    return this.http.get<Array<Companie>>(this.apiUrl + '/companies?pageSize=' + maxItems
     + '&pageNumber=' + pageNumber + '&OrderBy=id',  { observe: 'response'});
  }
  getCompaniesBySearch(value: string): Observable<HttpResponse<Array<Companie>>> {
    return this.http.get<Array<Companie>>(this.apiUrl + '/companies?searchquery=' + value, { observe: 'response' });
  }
  getCompaniesByPagedSearch(value: string, maxItems: number, pageNumber: number): Observable<HttpResponse<Array<Companie>>> {
    value = value.trim();
    return this.http.get<Array<Companie>>(this.apiUrl +
      '/companies?searchquery=' + value + '&pageSize=' + maxItems + '&pageNumber=' + pageNumber + '&OrderBy=id', { observe: 'response' })
  }
  getLeads(): Observable<Array<Leads>> {
    return this.http.get<Array<Leads>>(this.apiUrl + '/companies/leads');
  }
  saveCompanie(companie: CompanieForCreation): Observable<Companie> {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    return this.http.post<Companie>(this.apiUrl + '/companies/', companie, {headers: headers});
  }
  updateCompanie(id: string, companie: CompanieForUpdate) {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    console.log(companie);
    return this.http.put(this.apiUrl + '/companies/' + id, companie)
  }
  deleteCompanie(id: string) {
    return this.http.delete(this.apiUrl + '/companies/' + id);
  }
}
