export abstract class CompanieAbstractBase {
  id: number;
  companyName: string;
  address: string;
  country: string;
  postCode: string;
  place: string;
  providerId: string;
  spanishAgency: boolean;
  anvrCode: string;
  basUser: Array<BasUser>;
  basUserId: string;
  merlinxAgencyID: string;
  merlinxAgencyUserID: string;
  merlinxAgents: string;
  vat: string;
  isBoeknueenhotelUser: boolean;
  isDiscoverCarsUser: boolean;
  consultant: string;
  agencyId: string;
  lead: string;
  leadsId: number;
  }

  export class BasUser {
    address: string;
    administrationName: string;
    anvrCode: string;
    anvrCodeMainOffice: string;
    email: string;
    id: number;
    mainOffice: string;
    name: string;
    telephoneNumber: string;
  }
