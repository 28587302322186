export abstract class AgencyAbstractBase {
  id: number;
  name: string;
  address: string;
  city: string;
  country: string;
  zipCode: string;
  phoneNumber: string;
  vatNumber: string;
  group1Id: number;
  group2Id: number;
  group1: Group;
  group2: Group;
  exactAccountfullName: string;
  exactAccount: ExactAccount;
  exactAccountId: string;
  group1Name: string;
  group2Name: string;
  companyLogo: string;
  lenguagge: string;
  }
  
  export class Group {
    id: number;
    name: string;
  }
  export class ExactAccount{
    id: string;
    name: string;
    status: string;
    code: string;
  }
